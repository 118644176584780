<template>
	<div class="pageContainer">
		
		<index :type="type" @setType="settype"></index>

	</div>
</template>

<script>
import Index from '@/components/data/index.vue';
export default {
	name: 'FrontendPcBigdataPrepare',
	components: { Index },
	data() {
		return {
			type: 3, //1代表 拟上市  2正在公告  3 成交
		}
	},
	methods:{
		settype(settype){
			this.type = settype
		}
	}
};
</script>

<style lang="scss" scoped>
.pageContainer {
	width: 100%;
	overflow-y: scroll;
}
</style>